<template>
    <div>
      <invoice-code-report-detail-form :getClientsInvoiceCodeList="getClientsInvoiceCodeList" ref="invoiceCodeReportDetailFormModal"></invoice-code-report-detail-form>
      <b-row>
        <b-col class="col-12">
          <div style="float:left;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-form-input size="sm" v-model="searchTerm" :placeholder="$t('others.search')" type="text"
                  class="d-inline-block" />
              </div>
            </b-form-group>
          </div>
          <div style="float:right;" class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button style="float:right;" class="mr-1" size="sm"  variant="outline-primary" onclick="document.getElementById('serviceFileSelect').click()">{{ $t('clientsInvoiceCode.upload_from_excel') }}</b-button>
                <b-form-file size="sm" style="display:none" id="serviceFileSelect" accept="*.*" @change="getUploadFromExcel" :placeholder=" $t('clientsInvoiceCode.upload_from_excel')" :drop-placeholder=" $t('clientsInvoiceCode.upload_from_excel')" />
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table 
      id="clientsInvoiceCodeTable"
      ref="clientsInvoiceCodeTable" 
      :columns="columns" 
      :rows="rows" 
      styleClass="vgt-table condensed tableSize"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }" 
        theme="my-theme">
        <div slot="emptystate">
          {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'lawServiceType'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.service_type') }}
            </span>
            <span v-else-if="props.column.field === 'lawCostingType'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.costing_type') }}
            </span>
            <span v-else-if="props.column.field === 'code'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.code') }}
            </span>
            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.name') }}
            </span>
            <span v-else-if="props.column.field === 'description'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.description') }}
            </span>
            <span v-else-if="props.column.field === 'vatRate'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.vat_rate') }}
            </span>
            <span v-else-if="props.column.field === 'accountingNo'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.accounting_no') }}
            </span>
            <span v-else-if="props.column.field === 'accountingDescription'" class="text-nowrap">
                {{ $t('clientsInvoiceCode.accounting_description') }}
            </span>
            <span v-else-if="props.column.field === 'process'" class="text-nowrap">
                {{ $t('others.process') }}
            </span>
        </template>
        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title="$t('others.view')" v-b-modal.modal-top variant="primary"
                class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)">  
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
                <b-form-select v-model="pageLength" :options="['10', '50', '100', '1000']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
             <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                 <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                 <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
             </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      lawClientId: {type:Number}
    },
    mounted(){
      this.getClientsInvoiceCodeList();
    },
    data() {
      return {
        pageLength: 10,
        dir: false,
        clientsInvoiceCodeList: '',
        columns: [
            {
                label: this.$t('invoiceCodeReport.service_type'),
                field: `lawServiceType`,
            },
            {
                label: this.$t('invoiceCodeReport.costing_type'),
                field: `lawCostingType`,
            },
            {
                label: this.$t('invoiceCodeReport.code'),
                field: `code`,
            },
            {
                label: this.$t('invoiceCodeReport.name'),
                field: `name`,
            },
            {
                label: this.$t('invoiceCodeReport.description'),
                field: `description`,
            },
            {
                label: this.$t('invoiceCodeReport.vat_rate'),
                field: `vatRate`,
            },
            {
                label: this.$t('invoiceCodeReport.accounting_no'),
                field: `accountingNo`,
            },
            {
                label: this.$t('invoiceCodeReport.accounting_description'),
                field: `accountingDescription`,
            },
            {
                label: 'Process',
                field: 'process',
                width: '10em'
            },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    methods: {
      async getInfo(id) {
        this.$refs.invoiceCodeReportDetailFormModal.showInfo(id)
      },
      async getUploadFromExcel(e) {
            var fileInfo = await this.$getFileInfo(e.target.files[0])
            if(fileInfo != null)
            { 
              const data = {
                    base64: fileInfo.base64,
                    fileName: fileInfo.name,
                    lawClientId: this.lawClientId,
                }

                const controller = {
                    name: 'LawService',
                    actionName: 'ReadLawServicesFromExcelSaveToClient'
                }
                const payload = {
                    data: data,
                    controller: controller
                }

                let loader = this.$loading.show()
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.$SaveAlert()
                        this.getClientsInvoiceCodeList()
                    } 
                    else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                    loader.hide()
                })                   
            }
      },
      getClientsInvoiceCodeList() {
        this.rows = []
        const data = { id: this.lawClientId }
        const controller = { name: 'LawService', actionName: 'GetLawServiceListByLawClientId' }
        const payload = { data: data, controller: controller }
        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
          if (response.data.resultStatus == true) {
            response.data.lawServiceList.forEach(element => {
                this.rows.push(element)
            })
          }
          else {
            this.$WarningAlert(response.data.resultMessage)
          }
        }
        )
      },
    },
  }
  </script>
  <style lang="scss" >
    .tableSize {
      font-size: 13px!important;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      table-layout: auto;
  }
  </style>
  