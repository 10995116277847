<template>
    <div>
      <!-- modal -->
      <b-modal id="invoiceCodeReportDetailFormModal" ref="invoiceCodeReportDetailFormModal" centered size="lg" no-close-on-backdrop :title=" $t('invoiceCodeReportDetail.info')" :cancel-title=" $t('others.cancel_title')"
        :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="invoice-code-report-detail-name-input">{{ $t('invoiceCodeReportDetail.name') }}</label>
                        <b-form-input id="invoice-code-report-detail-name-input" size="sm" v-model="name" maxlength="250" />
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="invoice-code-report-detail-code-input">{{ $t('invoiceCodeReportDetail.code') }}</label>
                        <b-form-input disabled id="invoice-code-report-detail-code-input" size="sm" v-model="code" maxlength="20" />
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <label for="invoice-code-report-detail-vat-rate-input">{{ $t('invoiceCodeReportDetail.vat_rate') }}</label>
                        <b-form-input disabled id="invoice-code-report-detail-vat-rate-input" size="sm" v-model="vatRate" maxlength="250" />
                    </b-form>
                </b-col>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <label for="invoice-code-report-detail-accounting-no-input">{{ $t('invoiceCodeReportDetail.accounting_no') }}</label>
                        <b-form-input disabled id="invoice-code-report-detail-accounting-no-input" size="sm" v-model="accountingNo" maxlength="20" />
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <label for="invoice-code-report-detail-service-type-input">{{ $t('invoiceCodeReportDetail.service_type') }}</label>
                        <b-form-select disabled size="sm" v-model="serviceTypeSelect" value-field="id" text-field="name" :options="serviceTypeOption" />
                    </b-form>
                </b-col>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <label for="invoice-code-report-detail-costing-type-input">{{ $t('invoiceCodeReportDetail.costing_type') }}</label>
                        <b-form-select disabled size="sm" v-model="costingTypeSelect" value-field="id" text-field="name" :options="costingTypeOption" />
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <label for="invoice-code-report-detail-description-input">{{ $t('invoiceCodeReportDetail.description') }}</label>
                        <b-form-group>
                            <b-form-textarea id="invoice-code-report-detail-description-input" :placeholder=" $t('invoiceCodeReportDetail.description')" v-model="description" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6 mt-1">
                    <b-form>
                        <label for="invoice-code-report-detail-accounting-description-input">{{ $t('invoiceCodeReportDetail.accounting_description') }}</label>
                        <b-form-group>
                            <b-form-textarea id="invoice-code-report-detail-accounting-description-input" :placeholder=" $t('invoiceCodeReportDetail.accounting_description')" v-model="accountingDescription" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    props: {
      getInvoiceCodeReportDetailList: {type: Function},
      invoiceCodeReportDetailList: {type: Function},
      getClientsInvoiceCodeList: {type: Function}
    },
    data() {
      return {
        id: 0,
        code: '',
        name: '',
        vatRate: '',
        accountingNo: '',
        serviceTypeSelect: 0,
        serviceTypeOption: [],
        costingTypeSelect: 0,
        costingTypeOption: [],
        description: '',
        accountingDescription: ''
      }
    },
    methods: {
      showInfo(id) {  
        this.$refs['invoiceCodeReportDetailFormModal'].show()
        this.id = id
        const data = {id: this.id}
        const controller = {name : 'LawService'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if(response.data.resultStatus == true){
                this.code = response.data.lawService.code
                this.name = response.data.lawService.name
                this.vatRate = response.data.lawService.vatRate
                this.accountingNo = response.data.lawService.accountingNo
                this.serviceTypeSelect = response.data.lawService.lawServiceTypeId
                this.serviceTypeOption = response.data.lawServiceTypeList
                this.costingTypeSelect = response.data.lawService.lawCostingTypeId
                this.costingTypeOption = response.data.lawServiceCostingTypeList
                this.description = response.data.lawService.description
                this.accountingDescription = response.data.lawService.accountingDescription
            } else {
                this.$bvModal.hide('invoiceCodeReportDetailFormModal')
                this.$WarningAlert(response.data.resultMessage)
            }  
        })  
      },
      addOrUpdate() {
        const data = {id: this.id, code: this.code, name: this.name, vatRate: this.vatRate, accountingNo: this.accountingNo, serviceTypeId: this.serviceTypeSelect,
        costingTypeId: this.costingTypeSelect, description: this.description, accountingDescription: this.accountingDescription}
        const controller = {name : 'LawService'}
        const payload = {data: data, controller: controller}
        this.$store.dispatch('moduleAuth/save',payload).then((response) => {
            if(response.data.resultStatus == true){
                this.$bvModal.hide('invoiceCodeReportDetailFormModal')

                if(this.getClientsInvoiceCodeList)
                    this.getClientsInvoiceCodeList()
                if(this.invoiceCodeReportDetailList)
                    this.invoiceCodeReportDetailList()
                this.$SaveAlert()
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  [dir=ltr] .modal-header .close {
      margin: -0.4rem -0.4rem -0.8rem auto;
  }
  [dir] label {
      margin-bottom: 0.2857rem;
      font-weight: bold;
  }
  .modal .modal-content {
      overflow: visible;
      margin-bottom: auto;
  }
  [dir] .invalid-tooltip {
      padding: 0.4rem 0.775rem!important;
      margin-top: -1.5rem!important;
      background-color: #ea5455!important;
      border-radius: 0.358rem!important;
      left: 20px!important
  }
  </style>
  