<template>
<div>
    <!-- modal -->
    <b-modal id="dailyworkFormModal" ref="dailyworkFormModal" centered size="xl" no-close-on-backdrop :title="'Dailywork - '+recordNo +'-'+shortName" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-service-or-expense-input">{{ $t('dailywork.service_or_expense') }}</label>
                        <b-form-group>
                            <b-form-select :disabled="id > 0" id="dailywork-service-or-expense-input" size="sm" v-model="lawServiceTypeSelect" value-field="id" text-field="name" :options="lawServiceTypeOption" @change="serviceTypeChange()" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-billing-status-input">{{ $t('dailywork.billing_status') }}</label>
                        <b-form-group>
                            <b-form-select id="dailywork-billing-status-input" size="sm" v-model="lawBillingStatusSelect" value-field="id" text-field="name" :options="lawBillingStatusOption" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-employee-input">{{ $t('dailywork.employee') }}</label>
                        <b-form-group>
                            <b-form-select id="dailywork-employee-input" size="sm" v-model="employeeSelect"  @change="detectLawCostingType" value-field="id" text-field="name" :options="employeeOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-activity-input">{{ $t('dailywork.activity') }}</label>
                        <b-form-group>
                            <b-form-select id="dailywork-activity-input" size="sm" v-model="lawActivitySelect" value-field="id" text-field="name" :options="lawActivityOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-task-expense-input">{{serviceLabel}}</label>
                        <b-form-group>
                            <validation-provider :name=" $t('dailywork.service')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-select :disabled="id > 0" :state="errors.length > 0 ? false:null" id="dailywork-task-expense-input" size="sm" v-model="lawServiceSelect" value-field="id" text-field="name" :options="lawServiceOption" @change="serviceChange()" />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-document-no-input">{{ $t('dailywork.document_no') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="dailywork-document-no-input" v-model="documentNo" maxlength="300" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-document-date-input">{{ $t('dailywork.document_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('dailywork.document_date')" size="sm" v-model="documentDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-client-name-input">{{ $t('dailywork.client_name') }}</label>
                        <b-form-group>
                            <b-form-input disabled size="sm" id="dailywork-client-name-input" v-model="clientName" maxlength="300" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <b-form-group :label="$t('dailywork.invoice_agent_name')" label-for="dailywork-not-accounting-invoice-agent-input">
                            <v-select size="sm" id="dailywork-not-accounting-invoice-agent-input" v-model="dailyworkInvoiceAgentSelect" :options="dailyworkInvoiceAgentOption" @input="setClientId" label="clientName" @search="onInvoiceClientAccountingSearch">
                            </v-select>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="dailywork-agent-name-input">{{ $t('dailywork.agent_name') }}</label>
                        <b-form-group>
                            <b-form-input disabled size="sm" id="dailywork-agent-name-input" v-model="agentName" maxlength="300" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col v-if="lawServiceSelect == 1" class="col-lg-3">
                    <b-form>
                        <label for="dailywork-related-input">{{ $t('dailywork.related') }}</label>
                        <b-form-group>
                            <b-form-select id="dailywork-related-input" size="sm" v-model="relatedSelect" value-field="id" text-field="name" :options="relatedOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col v-else-if="lawServiceSelect == 2" class="col-lg-4">
                    <b-form>
                        <label for="dailywork-vendor-input">{{ $t('dailywork.vendor') }}</label>
                        <b-form-group>
                            <b-form-select id="dailywork-vendor-input" size="sm" v-model="vendorSelect" value-field="id" text-field="name" :options="vendorOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="dailywork-description-input">{{ $t('dailywork.description') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('dailywork.description')" rules="required">
                                <b-form-textarea :state="errors.length > 0 ? false:null" id="dailywork-description-input" :placeholder=" $t('dailywork.description')" v-model="description" rows="3" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="dailywork-accounting-input">{{ $t('dailywork.accounting') }}</label>
                        <b-form-group>
                            <b-form-textarea id="dailywork-accounting-input" :placeholder=" $t('dailywork.accounting')" v-model="accountingDescription" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-invoice-accounting-const-type-input">{{ $t('dailywork.costing_type') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="lawCostingTypeSelect" value-field="id" text-field="name" @change="detectLawCostingType" :options="lawCostingTypeOption" />
                    </b-form-group>
                </b-col>
                <b-col :hidden="lawCostingTypeSelect != 2"></b-col>
                <b-col :hidden="lawCostingTypeSelect == 2" class="col-lg-2 mt-1">
                    <label v-if="lawCostingTypeSelect == 3" for="dailywork-not-accounting-count-input">{{ $t('dailywork.hour') }}</label>
                    <label v-else for="dailywork-not-accounting-count-input">{{ $t('dailywork.count') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-count-input" v-model="count" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label v-if="lawCostingTypeSelect == 2" for="dailywork-unit-price-input">{{ $t('dailywork.price') }}</label>
                    <label v-else for="dailywork-unit-price-input">{{ $t('dailywork.unit_price') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-unit-price-input" v-model="unitPrice" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptionsForPrice"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-not-accounting-currency-input">{{ $t('dailywork.currency') }}</label>
                    <b-form-group>
                        <b-form-select size="sm" v-model="currencySelect" value-field="id" text-field="code" :options="currencyOption" />
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-withholding-tax-input">{{ $t('dailywork.withholding_tax') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-withholding-tax-input" v-model="withholdingTax" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2 mt-1">
                    <label for="dailywork-not-accounting-amount-input">{{ $t('dailywork.amount') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-amount-input" disabled v-model="price" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-discount-rate-input">{{ $t('dailywork.discount_rate') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-discount-rate-input" v-model="discountRate" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-discount-input">{{ $t('dailywork.discount') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-discount-input" disabled v-model="discount" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-vat-rate-input">{{$t('dailywork.vat_rate')}}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-vat-rate-input" v-model="vatRate" :onChange="priceCalculation()" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-vat-input">{{ $t('dailywork.vat') }}</label>
                    <b-form-group>
                        <cleave id="dailywork-not-accounting-vat-input" disabled v-model="vat" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2"></b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-not-accounting-total-input">{{ $t('dailywork.total') }}</label>
                    <b-form-group>
                        <cleave disabled id="dailywork-not-accounting-total-input" v-model="total" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                    </b-form-group>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDailyworkList: {
            type: Function
        },
        getDailyworkReportList: {
            type: Function
        },
    },
    watch: {
        lawServiceTypeSelect(val){
            if(val == 2){
                this.currencySelect = 4
            }
        }
    },
    data() {
        return {
            id: 0,
            recordId: 0,
            lawServiceSelect: 0,
            lawServiceOption: [],
            lawServiceList: [],
            lawServiceTypeSelect: 0,
            lawServiceTypeOption: [],
            lawActivitySelect: 0,
            lawActivityOption: [],
            employeeSelect: 0,
            employeeOption: [],
            dailyworkInvoiceAgentSelect: 0,
            dailyworkInvoiceAgentOption: [],
            matterName: '',
            clientName: '',
            agentName: '',
            invoiceAgentName: '',
            lawBillingStatusSelect: 0,
            lawBillingStatusOption: [],
            relatedSelect: 0,
            relatedOption: [],
            vendorSelect: 0,
            vendorOption: [],
            documentNo: '',
            documentDate: null,
            description: '',
            accountingDescription: '',
            lawCostingTypeSelect: 0,
            lawCostingTypeOption: [],
            baseLawCostingTypeOption: [],
            count: '',
            unitPrice: '',
            currencySelect: 0,
            currencyOption: [],
            withholdingTax: '',
            price: '',
            matterName: '',
            vat: 0,
            vatRate: 0,
            discount: 0,
            discountRate: 0,
            total: 0,
            cleaveOptions: {
                prefix: '',
                numeral: true,
                numeralPositiveOnly: true,
                noImmediatePrefix: true,
                rawValueTrimPrefix: true,
                numeralIntegerScale: 9,
                numeralDecimalScale: 2
            },
            cleaveOptionsForPrice: {
                prefix: '',
                numeral: true,
                numeralPositiveOnly: false,
                noImmediatePrefix: true,
                rawValueTrimPrefix: true,
                numeralIntegerScale: 9,
                numeralDecimalScale: 2
            },
            recordNo : '',
            shortName : '',
            serviceLabel : '',
        }
    },
    methods: {
        detectLawCostingType() {
            if (this.lawCostingTypeSelect == 2) {
                this.count = 1
            } else if (this.lawCostingTypeSelect == 3 && this.employeeSelect > 0 && this.currencySelect > 0) {
                this.getLawEmployeePrice()
            }
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        async serviceChange() {
            var serviceInfo = this.lawServiceList.filter(p => p.id == this.lawServiceSelect)
            this.count = 1
            this.vatRate = serviceInfo[0].vatRate
            this.lawCostingTypeSelect = serviceInfo[0].lawCostingTypeId
            this.detectLawCostingType()
            this.priceCalculation()
        },
        priceCalculation() {
            this.price = (parseFloat(this.count * parseFloat(this.unitPrice).toFixed(2)) + parseFloat(this.withholdingTax)).toFixed(2)
            this.discount = (this.price * this.discountRate / 100).toFixed(2)
            this.vat = ((this.price - this.discount) * this.vatRate / 100).toFixed(2)
            this.total = (parseFloat(this.price) - parseFloat(this.discount) + parseFloat(this.vat)).toFixed(2)
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.dailyworkInvoiceAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        serviceTypeChange() {
            this.lawServiceOption = this.lawServiceList.filter(p => p.lawServiceTypeId == this.lawServiceTypeSelect)

            debugger;
            this.lawCostingTypeOption = this.baseLawCostingTypeOption.filter(p => p.id != 0)
            if (this.lawServiceTypeSelect == 3){
                this.lawCostingTypeOption = this.lawCostingTypeOption.filter(p => p.id != 1)  
                this.serviceLabel = this.$t('dailywork.service')
            }            
            else if (this.lawServiceTypeSelect == 2){
                this.lawCostingTypeOption = this.lawCostingTypeOption.filter(p => p.id == 1)
                this.serviceLabel = this.$t('dailywork.expense')
                console.log(this.service)
            }                
            this.lawCostingTypeOption.splice(0, 0, this.$nullSelected())   
            this.lawServiceOption.splice(0, 0, this.$nullSelected())

            if (this.id == 0)
                this.lawServiceSelect = 0;
        },
        showInfo(id, recordId,recordNo,shortName) {
            this.$refs['dailyworkFormModal'].show()
            this.id = id
            this.recordId = recordId
            this.recordNo = recordNo
            this.shortName = shortName
            const data = {
                id: this.id,
                recordId: this.recordId
            }
            const controller = {
                name: 'LawDailywork'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if (response.data.lawDailywork.employeeId > 0) {
                        this.employeeSelect = response.data.lawDailywork.employeeId

                    } else {
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                    }
                    this.lawServiceSelect = response.data.lawDailywork.lawServiceId
                    this.lawServiceList = response.data.lawServiceList
                    this.lawServiceTypeSelect = response.data.lawDailywork.lawServiceTypeId
                    this.lawServiceTypeOption = response.data.lawServiceTypeList
                    this.lawActivitySelect = response.data.lawDailywork.lawActivityId
                    this.lawActivityOption = response.data.lawActivityList
                    this.employeeOption = response.data.employeeList
                    this.matterName = response.data.lawDailywork.matterName
                    this.clientName = response.data.lawDailywork.ownerClient
                    this.agentName = response.data.lawDailywork.agent
                    this.invoiceAgentName = response.data.lawDailywork.invoiceClient
                    this.lawBillingStatusSelect = response.data.lawDailywork.lawBillingStatusId
                    this.lawBillingStatusOption = response.data.lawBillingStatusList
                    this.relatedSelect = response.data.lawDailywork.relatedId
                    this.relatedOption = response.data.relatedList
                    this.vendorSelect = response.data.lawDailywork.vendorId
                    this.vendorOption = response.data.vendorList
                    this.documentNo = response.data.lawDailywork.documentNo
                    this.documentDate = response.data.lawDailywork.documentDate
                    this.description = response.data.lawDailywork.description
                    this.accountingDescription = response.data.lawDailywork.accountingDescription
                    this.lawCostingTypeSelect = response.data.lawDailywork.lawCostingTypeId
                    this.lawCostingTypeOption = response.data.lawCostingTypeList
                    this.baseLawCostingTypeOption = response.data.lawCostingTypeList
                    this.count = response.data.lawDailywork.count
                    this.unitPrice = response.data.lawDailywork.unitPrice
                    this.currencySelect = response.data.lawDailywork.currencyId
                    this.currencyOption = response.data.currencyList
                    this.withholdingTax = response.data.lawDailywork.withholdingTax
                    this.price = response.data.lawDailywork.price
                    this.matterName = response.data.lawDailywork.matterName
                    this.vatRate = response.data.lawDailywork.vatRate,
                        this.discountRate = response.data.lawDailywork.discount,

                        this.lawActivityOption.splice(0, 0, this.$nullSelected())
                    this.lawBillingStatusOption.splice(0, 0, this.$nullSelected())
                    if (this.id == 0) {
                        this.lawServiceTypeSelect = 3
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                        this.lawBillingStatusSelect = 1
                    } else {
                        this.lawBillingStatusSelect = response.data.lawDailywork.lawBillingStatusId
                        this.lawServiceTypeSelect = response.data.lawDailywork.lawServiceTypeId

                    }

                    this.serviceTypeChange()
                    this.priceCalculation()

                    if (response.data.lawDailywork.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.lawDailywork.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.dailyworkInvoiceAgentOption = clientAccountingResponse.data.clientList
                            this.dailyworkInvoiceAgentSelect = clientAccountingResponse.data.clientList[0]
                        })

                    } else {
                        this.dailyworkInvoiceAgentOption = []
                        this.dailyworkInvoiceAgentSelect = 0;
                    }

                } else {
                    this.$bvModal.hide('dailyworkFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {
                        id: this.id,
                        recordId: this.recordId,
                        lawServiceId: this.lawServiceSelect,
                        lawServiceTypeId: this.lawServiceTypeSelect,
                        lawActivityId: this.lawActivitySelect,
                        employeeId: this.employeeSelect,
                        matterName: this.matterName,
                        invoiceClientAccountingId: this.dailyworkInvoiceAgentSelect.clientAccountingId,
                        lawBillingStatusId: this.lawBillingStatusSelect,
                        relatedId: this.relatedSelect,
                        vendorId: this.vendorSelect,
                        documentNo: this.documentNo,
                        documentDate: this.documentDate,
                        description: this.description,
                        accountingDescription: this.accountingDescription,
                        lawCostingTypeId: this.lawCostingTypeSelect,
                        count: this.count,
                        unitPrice: this.unitPrice,
                        currencyId: this.currencySelect,
                        withholdingTax: this.withholdingTax,
                        price: this.price,
                        vatRate: this.vatRate,
                        discount: this.discountRate
                    }
                    const controller = {
                        name: 'LawDailywork'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('dailyworkFormModal')
                            if (this.getDailyworkList != null) {
                                this.getDailyworkList()
                            }
                            if (this.getDailyworkReportList != null) {
                                this.getDailyworkReportList()
                            }

                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        //STOCK-1144
        getLawEmployeePrice() {
            const data = {
                lawEmployeeId: this.employeeSelect,
                currencyId: this.currencySelect
            }
            const controller = {
                name: 'LawEmployeePrice',
                actionName: "GetLawEmployeePriceByLawEmployeeIdAndCurrencyId"
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true && response.data.lawEmployeePrice != null) {
                    this.unitPrice = parseFloat(response.data.lawEmployeePrice.price).toFixed(2)
                }
            })
        },
        //
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.cleaveStyle {
    max-height: 3em !important;
    height: 2.2em !important;
}
</style>
