<template>
<div>
    <!-- modal -->
    <b-modal id="lawDailyworkDescriptionFormModal" ref="lawDailyworkDescriptionFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawDailyworkDescription.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-lg-6 mt-1 mb-1">
                    <label for="law-dailywork-description-usd-input">{{ $t('lawDailyworkDescription.usd') }}</label>
                    <cleave id="law-dailywork-description-usd-input" v-model="usd" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                </b-col>
                <b-col class="col-lg-6 mt-1 mb-1">
                    <label for="law-dailywork-description-eur-input">{{ $t('lawDailyworkDescription.eur') }}</label>
                    <cleave id="law-dailywork-description-eur-input" v-model="eur" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6 mt-1 mb-1">
                    <label for="law-dailywork-description-gbp-input">{{ $t('lawDailyworkDescription.gbp') }}</label>
                    <cleave id="law-dailywork-description-gbp-input" v-model="gbp" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                </b-col>
                <b-col class="col-lg-6 mt-1 mb-1">
                    <label for="law-dailywork-description-chf-input">{{ $t('lawDailyworkDescription.chf') }}</label>
                    <cleave id="law-dailywork-description-chf-input" v-model="chf" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('lawDailyworkDescription.name')" rules="required">
                                <label for="law-dailywork-description-name-input">{{ $t('lawDailyworkDescription.name') }}</label>
                                <b-form-textarea id="law-dailywork-description-name-input" :placeholder=" $t('lawDailyworkDescription.name')" rows="3" v-model="name" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLawDailyworkDescriptionList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            name: '',
            chf: 0,
            eur: 0,
            gbp: 0,
            usd: 0,
            cleaveOptions: {
                prefix: '',
                numeral: true,
                numeralPositiveOnly: true,
                noImmediatePrefix: true,
                rawValueTrimPrefix: true,
                numeralIntegerScale: 9,
                numeralDecimalScale: 2
            },
        }
    },
    methods: {
        clear() {
            this.chf = 0;
            this.eur = 0;
            this.gbp = 0;
            this.usd = 0;
            this.name = '';
        },
        showInfo(id) {
            this.$refs['lawDailyworkDescriptionFormModal'].show()
            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawDailyworkDescription'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.name = response.data.lawDailyworkDescription.name
                    this.chf = response.data.lawDailyworkDescription.chf
                    this.eur = response.data.lawDailyworkDescription.eur
                    this.usd = response.data.lawDailyworkDescription.usd
                    this.gbp = response.data.lawDailyworkDescription.gbp
                } else {
                    this.$bvModal.hide('lawDailyworkDescriptionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                name: this.name,
                eur: this.eur,
                chf: this.chf,
                usd: this.usd,
                gbp: this.gbp,

            }
            const controller = {
                name: 'LawDailyworkDescription'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('lawDailyworkDescriptionFormModal')
                            this.getLawDailyworkDescriptionList()
                            this.$SaveAlert()
                            this.clear()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
